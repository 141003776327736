import React from 'react';
import Apollo13 from '../../static/apollo13.json';

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
    };
  }

  componentDidMount() {
    let i = 0;
    const intervalId = setInterval(() => {
      const currentState = this.state.logs;
      currentState.push(Apollo13[i]);
      i++;
      this.setState({
        logs: currentState,
      });
      if (i >= 4000) {
        i = 100;
      }
    }, 300);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    return (
      <div>
        <div className="error_ bcb cw">
          <div className="error_main abns top left x y f jcc abs aic cy">
            <h1 className="super h0 error__msg error__msg-big" data-text="404">
              404
            </h1>
          </div>
          <div className="error__return x y f jcs aie cw abs top left">
            <div>
              {this.state.logs.map((log, i) => (
                <div className="f jcs ais m0 p0" key={`${i}-e`}>
                  <span
                    className={`akkura xsmall m0 p0 error__time rel ${(i + 10) %
                      (Math.random() * 14) >
                      7 && 'error__glitch'}`}
                    data-text={`${log.start}:${log.end}`}
                  >
                    {log.start}:{log.end}:$
                  </span>
                  {log.source === 'Couch' ? (
                    <a
                      href="https://thecouch.nyc"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cy"
                    >
                      <span
                        className={`p0 m0 akkura xsmall ${(i + 10) %
                          (Math.random() * 14) >
                          7 && 'error__msg'} ${(i + 10) % (Math.random() * 20) > 7 &&
                          'error__block'}`}
                        data-text={`${log.message}`}
                      >
                        {log.message}
                      </span>
                    </a>
                  ) : (
                    <span
                      className={`p0 m0 akkura xsmall ${(i + 10) %
                        (Math.random() * 14) >
                        7 && 'error__msg'} ${(i + 10) % (Math.random() * 20) > 7 &&
                        'error__block'}`}
                      data-text={`${log.message}`}
                    >
                      {log.message}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
